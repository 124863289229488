import React from "react";
import { COORDINATES_MAP, STEP_LENGTH } from "./../constants/constants";
import { useState, useEffect, useRef } from "react";
import Dice from "./Dice";
import Ludo from "../engine/Ludo";
import { diceRoll } from "../socket/diceRoll";
import { useRecoilState, useRecoilValue } from "recoil";
import { getMoves } from "../socket/getMoves";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import WinnerPopup from "./WinnerPopup";
import PaymentDone from "./PaymentDone";

import {
  socketState,
  codeState,
  numPlayersState,
  colorState,
  maxPlayersState,
  moneyState
} from "../atoms/atom";

let FINISHED_PLAYERS = [];

const colors = ["blue", "yellow", "green", "red"];

function movePiece(pieceId, coordinateIndex) {
  var pieceElement = document.getElementById(pieceId);
  pieceElement.style.top =
    COORDINATES_MAP[coordinateIndex][1] * STEP_LENGTH + "%";
  pieceElement.style.left =
    COORDINATES_MAP[coordinateIndex][0] * STEP_LENGTH + "%";
}

function setInitialPosition() {
  const COORDINATE_OFFSETS = {
    0: 100,
    1: 200,
    2: 300,
    3: 400,
  };

  for (let player = 0; player <= 3; player++) {
    for (let piece = 0; piece <= 3; piece++) {
      const pieceId = `p${player}${piece}`;
      const coordinateIndex = COORDINATE_OFFSETS[player] + piece;
      const pieceElement = document.getElementById(pieceId);
      if (pieceElement) {
        pieceElement.style.top =
          COORDINATES_MAP[coordinateIndex][1] * STEP_LENGTH + "%";
        pieceElement.style.left =
          COORDINATES_MAP[coordinateIndex][0] * STEP_LENGTH + "%";
        pieceElement.style.display = "block";
      }
    }
  }
}

function highlightPieces(player) {
  for (var piece = 0; piece < 4; piece++) {
    const pieceId = `p${player}${piece}`;
    const pieceElement = document.getElementById(pieceId);
    pieceElement.classList.add("highlight");
  }
}

function unhighlightPieces() {
  document.querySelectorAll(".piece.highlight").forEach((ele) => {
    ele.classList.remove("highlight");
  });
}

function highlightActivePlayerBase(turn) {
  const activePlayerBase = document.querySelector(".player-base.highlight");
  if (activePlayerBase) {
    activePlayerBase.classList.remove("highlight");
  }
  document.querySelector(`#base-${turn}`).classList.add("highlight");
}

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function playMoveSound() {
  const moveSound = new Audio("/piece.mp3"); // Update the path to your sound file
  moveSound.play();
}

// function playDiceSound() {
//   const diceSound = new Audio("/dice.mp3"); // Update the path to your sound file
//   diceSound.play();
// }

async function performMoves(moves) {
  for (const move of moves) {
    console.log(move);
    const pieceId = `p${move.player}${move.piece}`;
    const coordinateIndex = move.position;
    movePiece(pieceId, coordinateIndex);
    playMoveSound();
    await delay(300); // Wait for 300 milliseconds before the next move
  }
}

function LudoUI() {
  const [moneyDeposited] = useRecoilState(moneyState);
  const code = useRecoilValue(codeState);
  const [numPlayers, setNumPlayers] = useRecoilState(numPlayersState);
  const [maxPlayers, setMaxPlayersState] = useRecoilState(maxPlayersState);

  const socket = useRecoilValue(socketState);
  const [color, setColor] = useRecoilState(colorState);
  const [canMove, setCanMove] = useState(false);

  const [diceValue, setDiceValue] = useState(1);
  const [turn, setTurn] = useState(0);
  const [isDiceDisabled, setIsDiceDisabled] = useState(false);

  const gameRef = useRef(null);

  const diceRef = useRef(null);

  const [winner, setWinner] = useState(null);
  const [payment, setPayment] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const navigate = useNavigate();

  const setPlayerWon = (player) => {
    if (maxPlayers == 2 && player == 1) player = 2;
    console.log("rrrrrr");
    setWinner(player); // Show the popup when a player wins
    setGameOver(true);
    const playerBase = document.getElementById(`base-${player}`);
    const img = document.createElement("img");
    img.src = `/winner1.png`;
    img.style.width = "100%";
    img.style.height = "100%";
    playerBase.appendChild(img);
  };

  const closePopup = () => {
    setWinner(null); // Close the popup
  };

  useEffect(() => {
    if(moneyDeposited==false) {
      navigate("../");
    }
  })

  useEffect(() => {
    if (maxPlayers > 0 && !gameRef.current) {
      gameRef.current = new Ludo(maxPlayers);
      console.log("Ludo game initialized with", maxPlayers, "players");
    }
  }, [maxPlayers]);

  const handlePieceClick = (e) => {
    e.preventDefault();
    const pieceId = e.currentTarget.id;
    const player = pieceId[1];
    const piece = pieceId[2];
    if (player == turn && colors.indexOf(color) == turn && canMove) {
      console.log(player, piece, diceValue);
      getMoves(socket, code, player, piece, diceValue); // send move to server
    }
  };

  useEffect(() => {
    unhighlightPieces();
    setIsDiceDisabled(false);
    highlightActivePlayerBase(turn);
  }, [turn]);

  useEffect(() => {
    setInitialPosition();
  }, []);

  const handleDice = (value) => {
    if (!canMove) {
      diceRoll(socket, code);
    }
  };

  const handleRoll = (value) => {
    console.log(`Rolled: ${value}`);
    setDiceValue(value);
    unhighlightPieces();
    const hasValidMove = gameRef.current.hasValidMove(turn, value);
    console.log("hasValidMove", hasValidMove);

    if (hasValidMove) {
      setCanMove(true);
      highlightPieces(turn);
      if (gameRef.current.allPiecesIn(turn, value)) {
        setTurn(gameRef.current.TURN);
      }
    } else {
      console.log(gameRef.current.TURN);
      setTurn(gameRef.current.TURN);
      console.log(gameRef.current.TURN);
    }
  };

  useEffect(() => {
    if (!socket) return;

    const handleMessage = (event) => {
      try {
        const message = JSON.parse(event.data.toString());
        console.log("Message from server:", message);

        if (message.type === "dice_roll") {
          if (message.turn === turn) {
            diceRef.current.rollToValue(message.diceValue);
            handleRoll(message.diceValue);
          }
        } else if (message.type === "move_piece") {
          const result = message.result;
          console.log("Result :", result);
          if (result != null && result.isMoveValid) {
            gameRef.current.move(
              message.game_player,
              message.piece,
              message.diceValue,
            );
            performMoves(result.MOVES);
            setTurn(gameRef.current.TURN);
            setIsDiceDisabled(false);
            setCanMove(false);
            unhighlightPieces();
          }
        } else if (message.type === "player_won") {
          setPlayerWon(message.player);
        } else if (message.type === "move_piece_test") {
          gameRef.current.CURRENT_POSITIONS[message.player][message.piece] =
            Number(message.position);
          movePiece(message.pieceId, Number(message.position));
        } else if (message.type == "wont_work") {
          toast.error("This won't work baby!");
        } else if(message.type == "payment_done") {
          setPayment(true);
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    };

    socket.onmessage = handleMessage;

    return () => {
      socket.onmessage = null;
    };
  }, [socket, turn]);

  const handleTest = (event) => {
    event.preventDefault(); // Prevents the page from refreshing on form submission
    const player = `P${Number(event.target.piece.value[0]) + 1}`;
    const piece = event.target.piece.value[1];
    const position = event.target.position.value;
    const pieceId = `p${event.target.piece.value[0]}${piece}`;
    console.log(`Player: ${player}, Piece: ${piece}, Position: ${position}`);
    socket.send(
      JSON.stringify({
        type: "move_piece_test",
        player: player,
        piece: piece,
        position: position,
        pieceId: pieceId,
        gameCode: code,
      }),
    );
  };

  return (
    <>
      <div
        className="top-0 w-full h-screen flex items-center justify-center"
        style={{
          backgroundImage: "url('/game-bg.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="flex flex-col items-center justify-center">
          <div className="relative top-0 w-full flex justify-between p-4">
            <div className="bg-gray-800 bg-opacity-70 p-2 rounded-lg">
              <h1 className="font-bold text-white font-custom text-2xl">
                Your Color:{" "}
                <span className={`text-color-${color.toLowerCase()} font-bold`}>
                  {color.toUpperCase()}
                </span>
              </h1>
            </div>
            <div className="bg-gray-800 bg-opacity-70 p-2 rounded-lg">
              <h1 className="font-bold text-white font-custom text-2xl">Game Code: {code}</h1>
            </div>
          </div>
          <div className="flex justify-center bg-grey">
            <div id="ludo-board" className="mx-4 justify-center bg-grey">
              <img
                src="/ludo-bg.jpg"
                className="w-full max-w-md sm:max-w-lg md:max-w-xl lg:max-w-2xl"
                style={{ height: "auto", maxHeight: "67vh" }} // Maintains aspect ratio
              />
              {[0, 1, 2, 3].map((player) =>
                [0, 1, 2, 3].map((piece) => (
                  <div
                    key={`p${player}${piece}`}
                    id={`p${player}${piece}`}
                    onClick={handlePieceClick}
                    className={`piece player-${player}-piece absolute`}
                  ></div>
                )),
              )}
              {[0, 1, 2, 3].map((base) => (
                <div
                  key={`base-${base}`}
                  id={`base-${base}`}
                  className="player-base"
                ></div>
              ))}
            </div>
          </div>
          <div className="flex justify-center mt-4">
            {gameOver ? (
              <button
                onClick={() => (window.location.href = "/")} // Use your navigation logic here
                className="px-6 py-3 bg-[#EEE4B1] hover:bg-[#D6CCA1] text-gray-800 font-bold rounded-md transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-[#EEE4B1] focus:ring-opacity-50"
              >
                Home
              </button>
            ) : (
              <Dice
                id="dice"
                ref={diceRef}
                disabled={isDiceDisabled}
                rollingTime={700}
                size={100}
                onRoll={handleDice}
                sound={"/dice.mp3"}
              />
            )}
          </div>{" "}
        </div>
        <div>

        {winner !== null ? (
          payment === false ? (
            <WinnerPopup player={winner} onClose={closePopup} />
          ) : (
            <PaymentDone player={winner} onClose={closePopup} />
          )
        ) : null}
      </div>

        <Toaster position="top-center" reverseOrder={false} />
      </div>
      {/* <div className="flex justify-center mt-4">
        <form
          onSubmit={handleTest} 
          className="flex flex-col items-center bg-gray-800 p-4 rounded-lg bg-opacity-70" 
        > 
          <div className="mb-2"> 
            <label className="text-white font-bold mr-2" htmlFor="piece"> 
              Piece: 
            </label> 
            <input 
              type="text" 
              id="piece" 
              name="piece" 
              className="p-1 rounded-md" 
              required 
            /> 
          </div> 
          <div className="mb-2"> 
            <label className="text-white font-bold mr-2" htmlFor="position"> 
              Position: 
            </label> 
            <input 
              type="text" 
              id="position" 
              name="position" 
              className="p-1 rounded-md" 
              required 
            /> 
          </div> 
          <button 
            type="submit" 
            className="bg-blue-500 text-white font-bold py-1 px-4 rounded-lg mt-2 hover:bg-blue-700" 
          > 
            Submit 
          </button> 
        </form> 
      </div>  */}
    </>
  );
}

export default LudoUI;
