import {
  BASE_POSITIONS,
  HOME_ENTRANCE,
  HOME_POSITIONS,
  PLAYERS,
  SAFE_POSITIONS,
  START_POSITIONS,
  STATE,
  TURNING_POINTS,
} from "../constants/constants";

class Ludo {
  constructor(numPlayers) {
    this.CURRENT_POSITIONS = {
      P1: [100, 101, 102, 103],
      P2: [200, 201, 202, 203],
      P3: [300, 301, 302, 303],
      P4: [400, 401, 402, 403],
    };
    this.TURN = 0;
    this.FINISHED_PLAYERS = [];
    this.NUM_PLAYERS = numPlayers;
    console.log("Players : ", this.NUM_PLAYERS);
  }

  checkKill(currentPlayer, currentPiece, currentPiecePosition) {
    if (SAFE_POSITIONS.indexOf(currentPiecePosition) != -1) {
      return [false, []];
    }
    console.log(this.CURRENT_POSITIONS);
    for (let player in this.CURRENT_POSITIONS) {
      for (let piece = 0; piece < 4; piece++) {
        if (
          player != currentPlayer &&
          currentPiecePosition == this.CURRENT_POSITIONS[player][piece]
        ) {
          const killedPlayer = Number(player[1]) - 1;
          const killedPiece = piece;
          const returnPosition = (killedPlayer + 1) * 100 + killedPiece;
          return [
            true,
            {
              player: killedPlayer,
              piece: killedPiece,
              position: returnPosition,
            },
          ];
        }
      }
    }
    return [false, []];
  }

  checkInHome(player, piece, position, currentPiecePosition) {
    console.log("cih", player, typeof player);
    if (currentPiecePosition != position && position == HOME_POSITIONS[player])
      return true;
    return false;
  }

  hasPlayerWon(player) {
    console.log("won : ", player);
    for (let piece = 0; piece < 4; piece++) {
      if (this.CURRENT_POSITIONS[player][piece] != HOME_POSITIONS[player])
        return false;
    }
    return true;
  }

  getNewPosition(player, piece, moveBy) {
    let PIECES_POSITION = this.CURRENT_POSITIONS[player];
    let CURRENT_PIECE_POSITION = PIECES_POSITION[piece];

    let MOVES = [];

    if (CURRENT_PIECE_POSITION + moveBy > HOME_POSITIONS[player]) {
      return MOVES;
    }

    if (CURRENT_PIECE_POSITION == HOME_POSITIONS[player]) {
      return MOVES;
    }

    while (moveBy > 0) {
      CURRENT_PIECE_POSITION++;
      console.log(CURRENT_PIECE_POSITION);
      if (CURRENT_PIECE_POSITION === TURNING_POINTS[player] + 1) {
        CURRENT_PIECE_POSITION = HOME_ENTRANCE[player][0];
      } else if (CURRENT_PIECE_POSITION === 52) {
        CURRENT_PIECE_POSITION = 0;
      }
      MOVES.push({
        player: this.TURN,
        piece: piece,
        position: CURRENT_PIECE_POSITION,
      });
      moveBy--;
    }
    return MOVES;
  }

  // switchTurn() {
  //   this.TURN++;
  //   if (this.TURN == 4) this.TURN = 0;
  //   const player = `P${this.TURN + 1}`;
  //   if (this.hasPlayerWon(player)) {
  //     this.switchTurn();
  //   }
  // }

  switchTurn() {
    this.TURN++;
    if (this.NUM_PLAYERS == 3 && this.TURN == 3) this.TURN = 0;
    if (this.NUM_PLAYERS == 2 && this.TURN == 1) this.TURN = 2;
    if (this.NUM_PLAYERS == 2 && this.TURN == 3) this.TURN = 0;
    if (this.TURN == 4) this.TURN = 0;
    const player = `P${this.TURN + 1}`;
    if (this.hasPlayerWon(player)) {
      console.log("test won");
      this.switchTurn();
    }
  }

  updateBoard(moves) {
    moves.forEach((move) => {
      const player = `P${Number(move.player) + 1}`;
      const piece = Number(move.piece);
      const coordinateIndex = move.position;
      this.CURRENT_POSITIONS[player][piece] = coordinateIndex;
    });
  }

  allPiecesIn(player, diceValue) {
    player = `P${Number(player) + 1}`;
    let f = true;
    for (let i = 0; i < 4; i++) {
      if (this.CURRENT_POSITIONS[player][i] != BASE_POSITIONS[player][i]) {
        f = false;
        break;
      }
    }
    if (f == true && diceValue != 6) {
      this.switchTurn();
    }
    return f;
  }

  hasValidMove(player, diceValue) {
    player = `P${Number(player) + 1}`;
    for (let piece = 0; piece < 4; piece++) {
      const currentPiecePosition = this.CURRENT_POSITIONS[player][piece];
      console.log(
        `Piece : ${piece}, CP : ${currentPiecePosition}, diceValue: ${diceValue}, x:${
          currentPiecePosition + diceValue
        }, HP :${HOME_POSITIONS[player]}`,
      );
      console.log(currentPiecePosition);
      if (
        diceValue == 6 &&
        currentPiecePosition == BASE_POSITIONS[player][piece]
      ) {
        return true;
      } else if (
        currentPiecePosition !== BASE_POSITIONS[player][piece] &&
        Number(currentPiecePosition) + Number(diceValue) <=
          HOME_POSITIONS[player]
      ) {
        return true;
      }
    }
    this.switchTurn();
    return false;
  }

  move(player, piece, diceValue) {
    // Validate inputs
    player = `P${Number(player) + 1}`;
    if (diceValue < 1 || diceValue > 6) return null;
    if (piece < 0 || piece > 3) return null;

    let RESULT = {
      player: this.TURN,
      piece: piece,
      isMoveValid: false,
      MOVES: [],
    };

    let PIECES_POSITION = this.CURRENT_POSITIONS[player];
    let CURRENT_PIECE_POSITION = PIECES_POSITION[piece];

    if (CURRENT_PIECE_POSITION === BASE_POSITIONS[player][piece]) {
      if (diceValue === 6) {
        RESULT.isMoveValid = true;
        RESULT.MOVES.push({
          player: this.TURN,
          piece: piece,
          position: START_POSITIONS[player],
        });
      }
    } else {
      let MOVES = this.getNewPosition(player, piece, diceValue);
      if (MOVES.length > 0) {
        RESULT.MOVES = MOVES;
        RESULT.isMoveValid = true;
        let position = RESULT.MOVES[RESULT.MOVES.length - 1].position;
        console.log(position);
        const kill = this.checkKill(player, piece, position);
        const inHome = this.checkInHome(
          player,
          piece,
          position,
          CURRENT_PIECE_POSITION,
        );
        console.log(kill);
        if (kill[0]) {
          RESULT.MOVES.push(kill[1]);
        }
        if (diceValue != 6 && !kill[0] && !inHome) this.switchTurn();
      }
    }
    if (RESULT.isMoveValid) this.updateBoard(RESULT.MOVES);
    return RESULT;
  }
}

export default Ludo;
